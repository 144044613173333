import React from "react";
import DeviderBlocks from "../DeviderBlocks/DeviderBlocks";
import classes from './SocialSites.module.scss'

const SocialSites = () => {
    return(
        <div className={classes.social}>
            <div className="g-container">
                <DeviderBlocks span={'Наши '} title = {'социальные сети'}/>
                <div  className={classes.social__wrapper}>
                    <div className={classes.social__wrapper__info}>
                        <div className={classes.social__wrapper__info_text}>
                            Чем больше людей узнает о нашей работе, тем ближе станет Прекрасная Беларусь Будущего. Каждый репост — это маленький вклад в борьбу с ворами и коррупционерами, вклад в будущее нашей страны.
                        </div>
                        {/* <button className={classes.social__wrapper__info_button}>Поделиться</button> */}
                    </div>
                    <div className={classes.social__wrapper__imgs}>
                        <div className={classes.social__wrapper__item}>
                            <a href="https://youtube.com/@tribunal__info"  target="_blank" rel="noreferrer"><img src = {require('../../images/png/youTubeButton.png')} alt = 'youtube' className={classes.social__wrapper__item_img}/></a>
                        </div>
                        <div className={classes.social__wrapper__item}>
                            <a href="http://Instagram.com/narodnytribunal"  target="_blank" rel="noreferrer"><img src = {require('../../images/png/instagramButton.png')} alt = 'instagram' className={classes.social__wrapper__item_img}/></a>
                        </div>
                        <div className={classes.social__wrapper__item}>
                            <a href="https://t.me/tribunal_info"  target="_blank" rel="noreferrer"><img src = {require('../../images/png/telegramButton.png')} alt = 'telegram' className={classes.social__wrapper__item_img}/></a>
                        </div>
                        {/* <div className={classes.social__wrapper__item}>
                            <a href="https://youtube.com/@tribunal__info"  target="_blank" rel="noreferrer"><img src = {require('../../images/png/facebookButton.png')} alt = 'facebook' className={classes.social__wrapper__item_img}/></a>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SocialSites