import React, {useEffect, useState} from "react";
import classes from './BaseMainInfo.module.scss'
import { connect } from "react-redux"
import ItemCriminalPersson from "../ItemCriminalPersson/ItemCriminalPersson";
import { findBaseFilterAndInput, setLoadingBasePage } from '../../bll/BaseCriminals'
import PaginatioonBase from "../PaginatioonBase/PaginatioonBase";

const BaseMainInfo = (props) => {

    const [input, setInput] = useState('')
    const [isSearchDisabled, setSearchDisabled] = useState(false)

    const mockData = [
        {
            first_name : "Александр",
            middle_name : "Григорьевич",
            id : "1a9beaf7-560d-4b5e-afe2-3c793627409e",
            photo : "/media/criminals/Photo_criminal.webp",
        },
    ]

    useEffect(() => {
        if (!!input ||
            props.base.activeCriminals.length > 0 ||
            props.base.activeTypeCrime.length > 0) 
        {
            setSearchDisabled(false)
        }
        else (
            setSearchDisabled(true)
    )
    }, [input, props.base.activeCriminals, props.base.activeTypeCrime])

    const onSearchButton = () => {
        props.setLoadingBasePage()
        props.findBaseFilterAndInput(input, props.base.activeCriminals, props.base.activeTypeCrime)
    }

    return props.base.criminalsList ? (
        <div className={classes.base}>
            <div className={classes.base__search}>
                <input 
                    placeholder = "Введите имя или фамилию"
                    className = {classes.base__search_input}
                    onChange = {(e) => setInput(e.target.value)}
                    value = {input}
                    onKeyDown={(e) => {
                        if (e.key === "Enter")
                            onSearchButton()
                    }}
                    />
                <button 
                    className={classes.base__search_button} 
                    onClick={onSearchButton}
                >
                    Поиск
                </button>
                <div className={classes.base__search__wrapper} onClick={onSearchButton}>
                    <div className={classes.base__search__wrapper__icon}>
                        <div className={classes.base__search__wrapper__icon_circle}/>
                        <div className={classes.base__search__wrapper__icon_rectangle}/>
                    </div>
                </div>
            </div>
            <div className={classes.base__commonInfo}>
                <p className={classes.base__commonInfo_requests}>Заявок: <span>{props.base.currentAmoutCriminals}</span></p>
                <p className={classes.base__commonInfo_already}>Уже в базе: <span>{props.base.totalCriminals}</span></p>
            </div>
            {props.base.isLoadingBase ?
                <div className={classes.base__loader}>
                    <div className={classes.base__loader_spin} />
                </div>
            :   
                <div className={classes.base_mobileScreenWrapper}>
                    <div className={classes.base__list}>
                        {props.base.criminalsList.map((el, index) => {
                            return <ItemCriminalPersson key={index} el={el} />
                        })}
                    </div>
                    {props.base.currentAmoutCriminals ? 
                        <div className={classes.base_pagination}>
                            <PaginatioonBase />
                        </div>
                    :
                        <div className={classes.base_emptyWrapper}>
                            <p className={classes.base_emptyWrapper_text}>Данных не найдено</p>
                        </div>
                    }
                </div>
            }
        </div>
    ) : {}
} 

const mapStateToProps = (state) => ({
    base: state.BaseCriminals
})
  
export default connect(mapStateToProps, { findBaseFilterAndInput, setLoadingBasePage })(BaseMainInfo)