import React from "react";
import classes from './DeviderBlocks.module.scss'
import { ReactComponent as Arrow} from "../../images/svg/arrowRight.svg";

const DeviderBlocks = (props) => {
    return(
        <div className={classes.devider}>
            {props.arrow 
            ?
            <div className={classes.devider__wrapper}>
                <div className={classes.devider__title}>
                    <pre>
                        <span className={classes.devider__title_span}>
                            {props.span}
                        </span>
                    </pre>
                    {props.title}
                </div>
                <div className={classes.devider__wrapper__watchAll} onClick = {props.onClick}>
                    <div className={classes.devider__wrapper__watchAll_text}>Смотреть все</div>
                    <Arrow className={classes.devider__wrapper__watchAll_arrow} fill="black"/>
                </div>
            </div>
            :
            <div className={classes.devider__title}>
                <pre>
                    <span className={classes.devider__title_span}>
                        {props.span}
                    </span>
                </pre>
                {props.title}
            </div>
            }
            <div className={classes.devider_devider}/>
        </div>
    )
}

export default DeviderBlocks