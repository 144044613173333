import React from "react";
import classes from './PaginatioonBase.module.scss'
import { connect } from "react-redux";
import { changeActiveBasePagination, setLoadingBasePage } from "../../bll/BaseCriminals";

const PaginatioonBase = (props) => {

    const pages = []

    for(let i = 1; i <= props.search.totalPageBase; i++) {
        pages.push(i)
    }

    const onClick = (num) => {
        if (num !== props.search.currentPageBase) {
            props.setLoadingBasePage()
            window.scrollTo(0, 550);
            props.changeActiveBasePagination(num)
        }
    }

    return(
        <div className={classes.pagination}>
            {pages.map((el,index) => {
                return <div 
                    key={index} 
                    className={el === props.search.currentPageBase ? classes.pagination_active : classes.pagination_number}
                    onClick = {() => onClick(el)}>
                        {el}
                </div>
            })}
        </div>
    )
}

const mapStateToProps = (state) => ({
    search: state.BaseCriminals
})
  
export default connect(mapStateToProps, { changeActiveBasePagination, setLoadingBasePage })(PaginatioonBase)