import React from "react";
import classes from './OurProjects.module.scss'
import DeviderBlocks from "../DeviderBlocks/DeviderBlocks";
import OneProject from "../OneProject/OneProject";
import { useNavigate } from "react-router-dom";

const OurProjects = () => {

    const onClick = () =>{
        navigate("/investigations");
    }

    const navigate = useNavigate();

    return(
        <div className={classes.projects}>
            <div className="g-container">
                {/* <DeviderBlocks span={'Изучайте '} title = {'наши проекты'}/>
                <div className={classes.projects__wrappersProjects}>
                    <OneProject path={require('../../images/jpg/project1.jpg')} title={'Владеете информацией о документах и нарушениях'} button={'Пополнить базу'} onClick={() => onClick(1)}/>
                    <OneProject path={require('../../images/jpg/project2.jpg')} title={'Готовы учавствовать в волонтерской программе'} button={'Отправить заявку'} onClick={() => onClick(2)}/>
                    <OneProject path={require('../../images/jpg/project3.jpg')} title={'Имеете возможность поддержать финансово'} button={'Поддержать'} onClick={() => onClick(3)}/>
                    <OneProject path={require('../../images/jpg/project4.jpg')} title={'Знаете данные преступников или владеете информацией'} button={'Пополнить базу'} onClick={() => onClick(4)}/>
                </div> */}
                <div className = {classes.projects__secondBlock}>
                    <DeviderBlocks span={'Смотрите '} title = {'наши расследования'} arrow={true} onClick={onClick}/>
                </div>
                <img src={require('../../images/png/starBg.png')} className={classes.projects_img} alt="background"/>
                <img src={require('../../images/png/fistBg.png')} className={classes.projects_imgFist} alt="background"/>
                <img src={require('../../images/png/courtBg.png')} className={classes.projects_imgCourt} alt="background"/>
            </div>
        </div>
    )
}

export default OurProjects