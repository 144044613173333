const ID_VIDEO = 'ID_VIDEO'
const CONTENT_VIDEO = 'CONTENT_VIDEO'
const DESCR_VIDEO = 'DESCR_VIDEO'
const TITLE_VIDEO = 'TITLE_VIDEO'
const LINK_VIDEO = 'LINK_VIDEO'
const BANNER_VIDEO = 'BANNER_VIDEO'
const PREVIEW_IMG = 'PREVIEW_IMG'


const initialState = {
    id: '',
    content: '',
    descr: '',
    title: '',
    link: '',
    banner: '',
    previewImg: '',
}

const ItemInvestigation = (state = initialState, action) => {
    switch(action.type){
        case ID_VIDEO:
            return{
                ...state,
                id: action.payload
            }
        case CONTENT_VIDEO:
            return{
                ...state,
                content: action.payload
            }
        case DESCR_VIDEO:
            return{
                ...state,
                descr: action.payload
            }
        case TITLE_VIDEO:
            return{
                ...state,
                title: action.payload
            }
        case LINK_VIDEO:
            return{
                ...state,
                link: action.payload
            }
        case BANNER_VIDEO:
            return{
                ...state,
                banner: action.payload
            }
        case PREVIEW_IMG:
            return {
                ...state,
                previewImg: action.payload
            }
        default:
            return state;
    }
}

// export const changeHeaderValue = (number) =>({type: CHANGE_HEADER, number})
export const getOneInvestigation = (id) => {
    return async (dispatch) => {
        const video = await fetch(`${process.env.REACT_APP_URL}/api/v1/investigations/${id}`);
        const resultVideo = await video.json();
        dispatch({ type: ID_VIDEO, payload: resultVideo.id});
        dispatch({ type: CONTENT_VIDEO, payload: resultVideo.content});
        dispatch({ type: DESCR_VIDEO, payload: resultVideo.description});
        dispatch({ type: TITLE_VIDEO, payload: resultVideo.title});
        dispatch({ type: LINK_VIDEO, payload: resultVideo.youtube_link});
        dispatch({ type: BANNER_VIDEO, payload: resultVideo.banner_img});
        dispatch({ type: PREVIEW_IMG, payload: resultVideo.preview_img });
    }
}

export default ItemInvestigation