import React, { useState } from "react";
import classes from './FAQ.module.scss'
import DeviderBlocks from "../DeviderBlocks/DeviderBlocks";
import ElementFaq from "../ElementFaq/ElementFaq";

const FAQ = () => {

    return(
        <div className={classes.faq}>
            <div className="g-container">
                <DeviderBlocks title = {'Ответы на вопросы'}/>
                <ElementFaq 
                title = {'Основная цель и задачи Белорусского Народного Трибунала'} 
                descr = {'Главная цель нашей инициативы — документирование и расследование нарушений прав человека, совершенных властями Беларуси, особенно во время политического кризиса после выборов 2020 года. Это включает в себя сбор свидетельств о насилии, незаконных арестах и других формах репрессий, а также предоставление информации и поддержки жертвам.'}
                />
                <ElementFaq 
                title = {'Сбор и обработка информации о нарушениях прав человека'} 
                descr = {'Трибунал собирает информацию через различные каналы, включая прямые свидетельства от жертв, отчеты правозащитных организаций, открытые источники данных, и медиа-материалы. Эта информация затем анализируется для документирования случаев нарушений и подготовки докладов'}
                />
                <ElementFaq 
                title = {'Как сообщить информацию Трибуналу'} 
                descr = {'Если вы хотите сообщить информацию или предоставить доказательства нарушений со стороны режима лукашенко, то вы можете обратиться к нам официальный сайт или социальные сети. Вы можете воспользоватся формой для заполнения или отправить сообщение с помощью контактную информацию. Важно сохранять конфиденциальность и безопасность информаторов, поэтому мы принимаем меры для защиты идентичности ваших данных'}
                />
                <ElementFaq 
                title = {'Последствия и влияние работы Трибунала на политическую ситуацию в Беларуси'} 
                descr = {'Хотя мы не имеем официальной юридической силы, наша работа способствует повышению осведомленности о ситуации в стране и может оказывать давление на международное сообщество для принятия мер в отношении белорусского режима. Также, собранные данные могут быть использованы в будущих юридических процессах против ответственных за нарушения прав человека'}
                />
            </div>
        </div>
    )
}

export default FAQ