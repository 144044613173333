import React, {useEffect} from "react";
import classes from './SupportUs.module.scss'
import Header from "../../components/Header/Header";
import flags from '../../images/jpg/bel-ukr flags.png'
import solidarity from '../../images/png/bel-ukr-solidarity.png'
import Footer from "../../components/Footer/Footer";
import HelpUs from "../../components/HelpUs/HelpUs";
import copy from '../../images/png/copy.png'
import qr from '../../images/png/open.png'
import cryptoName from '../../images/png/bitcoin.png'
import DeviderBlocks from "../../components/DeviderBlocks/DeviderBlocks";
import FAQ from "../../components/FAQ/FAQ";
import ItemCryptoWallet from "../../components/ItemCryptoWallet/ItemCryptoWallet";

const SupportUs = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    })

    return(
        <div className={classes.support}>
            <div className="g-wrapperPage">
                <Header path = {9}/>
                <div className={classes.support__wrapper}>
                    <div className={classes.support__wrapper__main}>
                        <div className="g-container">
                            <div className={classes.support__wrapper__main__head}>
                                <img src={flags} className={classes.support__wrapper__main__head__title_img} alt="flags belarusian and ukranian"/>
                                <img src={solidarity} className={classes.support__wrapper__main__head__title_solidarity} alt="belarusian and ukranian solidarity"/>
                                <div className={classes.support__wrapper__main__head__title}>
                                    <h2 className={classes.support__wrapper__main__head__title_h2}>БЕЛАРУСКИЙ НАРОДНЫЙ ТРИБУНАЛ</h2>
                                </div>
                                <div className={classes.support__wrapper__main__head_devider}/>
                                <p className={classes.support__wrapper__main__head_text}>Мы — команда небезразличных людей, которые не могут спокойно смотреть
                                    на то беззаконие, которое происходит в нашей стране.</p>
                                <p className={classes.support__wrapper__main__head_text}>Донатам и своей поддержкой вы помогаете серверу и команде!
                                    Мы ценим каждого, кто уже оставил свое пожертвование. Это очень, очень много значит для нас!</p>
                            </div>
                        </div>
                    </div>
                    <HelpUs />
                    <FAQ />
                    <Footer />
                </div>
            </div>
        </div>
    )
}

export default SupportUs