import React, {memo} from "react";
import classes from './ItemMenuBase.module.scss'
import ItemBaseMenuElement from "../ItemBaseMenuElement/ItemBaseMenuElement";

const ItemMenuBase = (props) => {
    return(
        <div className={classes.section}>
            <p className={classes.section_title}>{props.title}</p>
            <div className={classes.section_devider}/>
            {props.obj.map((el) => {
                return <ItemBaseMenuElement 
                    key = {el.id} 
                    title = {el.title} 
                    isActive = {props.activefilter.includes(el.id)}
                    func = {props.func}
                    prevValue = {props.obj}
                    onClick = {() => props.onClick(el.id)}/>
            })}
        </div>
    )
}

export default React.memo(ItemMenuBase)