import React from "react";
import classes from './Footer.module.scss'
// import {ReactComponent as Facebook} from '../../images/svg/Facebook.svg'
import {ReactComponent as Instagram} from '../../images/svg/Instagram.svg' 
import {ReactComponent as Youtube} from '../../images/svg/YouTubeFooter.svg'
import {ReactComponent as Telegram} from '../../images/svg/Telegram.svg'
import { Link } from "react-router-dom";

const Footer = () => {
    return(
        <section className={classes.footer}>
            <div className="g-container">
                <div className={classes.footer__block}>
                    <img src={require('../../images/png/mainLogo.png')} alt = 'main logoBNT' className={classes.footer__block_image}/>
                    <div className={classes.footer__block_title}>Беларускi Народны Трыбунал</div>
                </div>
                <div className={classes.footer__info}>
                    <p className={classes.footer__info_basic}>
                        Беларуский Народный Трибунал (БНТ) — гражданская инициатива, которая занимается расследованием, раскрытием и фиксированием коррупционных правонарушений в высших органах власти Республики Беларусь. 
                    </p>
                    
                    <div className={classes.footer__info__topic}>
                        <Link to="/base" className={classes.footer__info__topic_title}>Преступники</Link>
                        {/* <p className={`${classes.footer__info__topic_descr} ${classes.footer__info__topic_descr_inactive}`}>Топ-50 преступников</p> */}
                        <p className={`${classes.footer__info__topic_descr}`}>База преступников</p>
                        <p className={`${classes.footer__info__topic_descr}`}>Внести в базу</p>
                    </div>

                    <div className={classes.footer__info__topic}>
                        <Link to="/investigations" className={classes.footer__info__topic_title}>Инфоцентр</Link>
                        <p className={classes.footer__info__topic_descr}>Расследования</p>
                        {/* <p className={classes.footer__info__topic_descr}>Новости</p>
                        <p className={classes.footer__info__topic_descr}>СМИ о нас</p> */}
                    </div>

                    <div className={classes.footer__info__topic}>
                        <Link to="/about" className={classes.footer__info__topic_title}>Контакты</Link>
                        <p className={classes.footer__info__topic_descr}>Команда</p>
                        <p className={classes.footer__info__topic_descr}>tribunal@gmail.com</p>
                    </div>

                    <div className={classes.footer__info__topic}>
                        <Link to="/support" className={classes.footer__info__topic_title}>Поддержать</Link>
                        <p className={classes.footer__info__topic_descr}>Информационная</p>
                        <p className={classes.footer__info__topic_descr}>Материальная</p>
                    </div>
                </div>
                <div className={classes.footer__social}>
                    {/* <a href="https://youtube.com/@tribunal__info"  target="_blank" rel="noreferrer">
                        <Facebook className={`${classes.footer__social_img} ${classes.footer__social_img_fb}`} />
                    </a> */}
                    <a href="http://Instagram.com/narodnytribunal"  target="_blank" rel="noreferrer">
                        <Instagram className={`${classes.footer__social_img} ${classes.footer__social_img_inst}`} />
                    </a>
                    <a href="https://youtube.com/@tribunal__info"  target="_blank" rel="noreferrer">
                        <Youtube className={`${classes.footer__social_img} ${classes.footer__social_img_tube}`} />
                    </a>
                    <a href="https://t.me/tribunal_info"  target="_blank" rel="noreferrer">
                        <Telegram className={`${classes.footer__social_img} ${classes.footer__social_img_telega}`} />
                    </a>
                </div>
            </div>
        </section>
    )
}

export default Footer