import React from "react";
import classes from './ItemInvestigationList.module.scss'
import { Link } from "react-router-dom";

const ItemInvestigationList = (props) => {

    const path = `/investigations/${props.id}`

    return(
        <Link className={classes.item} to = {path}>
            <img src={`${process.env.REACT_APP_URL}${props.path}`} alt="investigation" className={classes.item_img}/>
            <div className={classes.item__wrapper}>
                <p className={classes.item__wrapper_title}>{props.title}</p>
                <p className={classes.item__wrapper_descr}>{props.descr}</p>
            </div>
        </Link>
    )
}

export default ItemInvestigationList