import React, {useCallback, memo, useState} from "react";
import classes from './BaseLeftMenu.module.scss'
import ItemMenuBase from "../ItemMenuBase/ItemMenuBase";
import { connect } from "react-redux"
import {setFilterCriminals, setfilterTypeCrimes} from '../../bll/BaseCriminals'

const BaseLeftMenu = (props) => {

    const [isFilterOpen, setFilterOpen] = useState(false)

    const onChangeCheckbox = useCallback((id, arr, func) => {
        if(arr.includes(id)){
            func(arr.filter((el) => el !== id))
        }
        else{
            func([...arr, id])
        }
    }, [props.base.activeCriminals, props.base.activeTypeCrime])

    const handleBurgerFilter = (boolean) => {
        setFilterOpen(boolean)
    }

    return(
        <>
            <div className={classes.menu}>
                <ItemMenuBase
                    title='Категории преступников'
                    obj={props.base.menuCriminals}
                    activefilter={props.base.activeCriminals}
                    onClick={(id) => onChangeCheckbox(id, props.base.activeCriminals, props.setFilterCriminals)}
                />
                <ItemMenuBase
                    title='Категории преступлений'
                    obj={props.base.menuTypeCrimes}
                    activefilter={props.base.activeTypeCrime}
                    onClick={(id) => onChangeCheckbox(id, props.base.activeTypeCrime, props.setfilterTypeCrimes)}
                />
            </div>
            <div className={classes.burgerSearch}>
                <div className={classes.burgerSearch_menu} onClick={() => handleBurgerFilter(true)}>
                    <span />
                </div>
                <div className={isFilterOpen ? classes.burgerSearch__content : `${classes.burgerSearch__content} ${classes.burgerSearch__content_closed}`}>
                    <div className={classes.burgerSearch__content_close} onClick={ () => handleBurgerFilter(false)}/>
                    <div className={classes.burgerSearch__content__wrapper}>
                        <ItemMenuBase
                            title='Категории преступников'
                            obj={props.base.menuCriminals}
                            activefilter={props.base.activeCriminals}
                            onClick={(id) => onChangeCheckbox(id, props.base.activeCriminals, props.setFilterCriminals)}
                        />
                        <ItemMenuBase
                            title='Категории преступлений'
                            obj={props.base.menuTypeCrimes}
                            activefilter={props.base.activeTypeCrime}
                            onClick={(id) => onChangeCheckbox(id, props.base.activeTypeCrime, props.setfilterTypeCrimes)}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => ({
    base: state.BaseCriminals
})
  
export default connect(mapStateToProps, {setFilterCriminals, setfilterTypeCrimes})(React.memo(BaseLeftMenu))