import React, {useEffect, useState} from "react";
import classes from './BasePersonalPage.module.scss'
import Header from "../../components/Header/Header";
import { Link, useParams } from 'react-router-dom';
import {getInfoAboutPerson} from '../../bll/BasePerson'
import { connect } from "react-redux";
import {ReactComponent as Arrow} from '../../images/svg/arrowRight.svg'
import {ReactComponent as Error} from '../../images/svg/error.svg'
import ItemBasePersonSocialSites from "../../components/ItemBasePersonSocialSites/ItemBasePersonSocialSites";

const BasePersonalPage = (props) => {

    let { id } = useParams();
    const [birth] = useState(`${props.base.birth.slice(8,10)}.${props.base.birth.slice(5,7)}.${props.base.birth.slice(0,4)}`)

    useEffect(() => {
        window.scrollTo(0, 0);
        props.getInfoAboutPerson(id)
    }, [])


    console.log('gello', props.base)

    return(
        <div className={classes.person}>
            <div className="g-wrapperPage">
                <Header path = {3}/>
                <div className={classes.person__back}>
                    <div className="g-container">
                        <Link className={classes.person__back_direction} to="/base">
                            <Arrow fill="white" className={classes.person__back_img}/>
                            <p>Вернутся к базе</p>
                        </Link>
                    </div>
                </div>
                <div className="g-container">
                    <div className={classes.person__wrapper}>
                        <div className={classes.person__wrapper__header}>
                            <img src={`${process.env.REACT_APP_URL}${props.base.photo}`} className={classes.person__wrapper__header_photo}/>
                            <div className={classes.person__wrapper__header__basic}>
                                <div className={classes.person__wrapper__header__basic__main}>
                                    <div>
                                        <p className={classes.person__wrapper__header__basic__main_name}>{props.base.surname}</p>
                                        <p className={classes.person__wrapper__header__basic__main_name}>{props.base.name}</p>
                                        <p className={classes.person__wrapper__header__basic__main_name}>{props.base.middlename}</p>
                                    </div>
                                    {/* <div className={classes.person__wrapper__header__basic__main__complain}>
                                        <p className={classes.person__wrapper__header__basic__main_error}>Сообщить об ошибке</p>
                                        <Error fill="#E63946" className={classes.person__wrapper__header__basic__main_img}/>
                                    </div> */}
                                </div>
                                <p className={classes.person__wrapper__header__basic_text}>{birth}, {props.base.address}</p>
                                <p className={classes.person__wrapper__header__basic_text}>Занимаемая должность: {props.base.job}</p>
                                <p className={classes.person__wrapper__header__basic_text}>
                                    Индивидуальный номер: 
                                    <span className={props.base.number !== 'Неопределенный' ? 
                                        classes.person__wrapper__header__basic_text_colorRed
                                    :
                                        classes.person__wrapper__header__basic_text_space
                                    }>
                                        {props.base.number}
                                    </span>
                                </p>
                                <div className={classes.person__wrapper__header__basic_social}>
                                    <p className={classes.person__wrapper__header__basic_text}>Соц. сети:</p>
                                    {props.base.social.length ?
                                        props.base.social.map((el) => {
                                            return(
                                                <ItemBasePersonSocialSites 
                                                    key = {el.id}
                                                    title = {el.title}
                                                    path = {el.link}/>
                                            )
                                        })
                                    :
                                        <p className={classes.person__wrapper__header__basic_text_space}>-</p>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className={classes.person__wrapper__content}>
                            <div className='ck-content'>
                                <div dangerouslySetInnerHTML={{__html: props.base.content}} className={classes.person__wrapper__content_parser}></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    base: state.BasePerson
})
  
export default connect(mapStateToProps, {getInfoAboutPerson})(BasePersonalPage)