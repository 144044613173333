import React, {useEffect, useState} from "react"
import { connect } from "react-redux";
import classes from './investigations.module.scss'
import Header from "../../components/Header/Header"
import Footer from "../../components/Footer/Footer"
import DeviderBlocks from "../../components/DeviderBlocks/DeviderBlocks";
import ItemInvestigationList from "../../components/ItemInvestigationList/ItemInvestigationList";
import Pagination from "../../components/Pagination/Pagination";
import {ReactComponent as Youtube} from '../../images/svg/youtubeWhite.svg'
import { ReactComponent as PlayVideo } from '../../images/svg/playVideo.svg'
import { Link } from "react-router-dom";

const Investigations = (props) => {

    const [link, setLink] = useState([])
    const [bg, setBg] = useState(`${process.env.REACT_APP_URL}/media/banners/IMG_7722_f90xUMu.JPEG`)
    const [youtube, setYouTube] = useState('https://www.youtube.com/watch?v=vpNUzNBw6FI&list=RDMMvpNUzNBw6FI&start_radio=1')
    const [id, setId] = useState('')
    const path = `/investigations/${id}`
    const [staticImg, setStaticImg] = useState('')
    const [toggle, setToggle] = useState(false)

    useEffect(() => {
        if(Object.keys(props.data.lastInvestigation).length){
            const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;

            setLink(youtube.match(regExp))
        }
    },[youtube])

    useEffect(() => {   
        setYouTube(props.data.lastInvestigation.youtube_link)
        setBg(`${process.env.REACT_APP_URL}${props.data.lastInvestigation.banner_img}`)
        setId(props.data.lastInvestigation.id)
        setStaticImg(`${process.env.REACT_APP_URL}${props.data.lastInvestigation.preview_img}`)
    },[Object.keys(props.data.lastInvestigation).length])

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    return Object.keys(props.data.lastInvestigation).length ? (
        <div className={classes.video}>
            <div className="g-wrapperPage">
                <Header path = {2}/>
                <div className={classes.video__wrapper}>
                    <div className={classes.video__wrapper__main} 
                        style={{ 
                            backgroundImage: `url(${bg})`,
                            height: '680px'}}
                            >
                    <div className={classes.video__wrapper__main_bg}/>
                        <div className="g-container">
                            <div className={classes.video__wrapper__main__head}>
                                <div className={classes.video__wrapper__main__head__left}>
                                    <div className={classes.video__wrapper__main__head__left__wrapper}>
                                        <div className={classes.video__wrapper__main__head__left__title}>
                                            <h2 className={classes.video__wrapper__main__head__left__title_h2}>{props.data.lastInvestigation.title}</h2>
                                            <h2 className={classes.video__wrapper__main__head__left__title_h2Mobile}>Последнее расследование</h2>
                                        </div>
                                        <div className={classes.video__wrapper__main__head__left_devider}/>
                                            <p className={classes.video__wrapper__main__head__left_text}>{props.data.lastInvestigation.description}</p>
                                        </div>
                                    <div className={classes.video__wrapper__main__head__left__btn}>
                                        <Link to = {path}>
                                            <button className={classes.video__wrapper__main__head__left__btn_primary}>Смотреть</button>
                                        </Link>
                                        <a className={classes.video__wrapper__main__head__left__btn__youtube} href={youtube}  target="_blank" rel="noreferrer">
                                            <Youtube stroke="white" className={classes.video__wrapper__main__head__left__btn__youtube_img}/>
                                            <div className={classes.video__wrapper__main__head__left__btn__youtube_text}>YouTube</div>
                                        </a>
                                        <Link to="/support" 
                                            className={classes.video__wrapper__main__head__left__btn_support}
                                            onClick={() => props.changeHeaderValue(9)}>
                                                Поддержать
                                        </Link>
                                    </div>
                                </div>
                                <div className={classes.video__wrapper__main__head__right}>
                                    {!toggle ?
                                        <div
                                            className={classes.video__wrapperVideoCover}
                                            onClick={() => setToggle(true)}
                                            style={{
                                                backgroundImage: `url(${staticImg})`,
                                                height: '372px',
                                                width: '564px'
                                            }}
                                        >
                                            <PlayVideo className={classes.video__wrapperVideoCover_playVideo}/>
                                        </div>
                                        :
                                        <div className={classes.video__wrapper__main__head__right}>
                                            <iframe
                                                width='564'
                                                autoplay
                                                height='372'
                                                src={`https://www.youtube.com/embed/${link[7]}`}
                                                title={props.data.lastInvestigation.title}
                                                name={props.data.lastInvestigation.title}
                                                frameBorder="0"
                                            />
                                        </div>
                                    }
                                    {/* <iframe width='564' height='372' src={`https://www.youtube.com/embed/${link[7]}`} title={props.data.lastInvestigation.title} name={props.data.lastInvestigation.title} frameBorder="0"/> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="g-container">
                        <div className={classes.video__wrapper__content}>
                            <DeviderBlocks span={'Смотрите '} title = {'наши расследования'}/>
                            <div className={classes.video__wrapper__content__investigation}>
                                {props.data.videoInvestigations.map((el, index) =>  {
                                    return <ItemInvestigationList 
                                        key = {index}
                                        path = {el.preview_img}
                                        title = {el.title}
                                        descr = {el.description}
                                        id = {el.id}/>
                                })}
                            </div>
                            <Pagination />
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </div>
    ) : (<></>)
} 

// export default Investigations

const mapStateToProps = (state) => ({
    data: state.AppReducers
})
  
export default connect(mapStateToProps, {})(Investigations)