import React, {useEffect, useState} from "react";
import classes from './PageInvestigation.module.scss'
import { useParams } from 'react-router-dom';
import { connect } from "react-redux";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import HelpUs from "../../components/HelpUs/HelpUs";
import { getOneInvestigation } from "../../bll/ItemInvestigation";
import { ReactComponent as YoutubeImg } from '../../images/svg/Youtube.svg'
import { ReactComponent as PlayVideo } from '../../images/svg/playVideo.svg'

const PageInvestigation = (props) => {

    let { id } = useParams();

    const [link, setLink] = useState([])
    const [youtube, setYouTube] = useState('')
    const [toggle, setToggle] = useState(false)
    const [staticImg, setStaticImg] = useState('')

    useEffect(() => {
        const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
        setLink(youtube?.match(regExp))
        setStaticImg(`${process.env.REACT_APP_URL}${props.video.previewImg}`)
    },[youtube])

    useEffect(() => {   
        if(props.video.link){
            setYouTube(props.video.link)
        }
    },[props.video.link])

    useEffect(() => {
        props.getOneInvestigation(id)
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    return(
        <div className={classes.investigation}>
            <div className="g-wrapperPage">
                <Header path = {2}/>
                <div className={classes.investigation__wrapper}>
                    <div className={classes.investigation__wrapper__main} 
                        style={{ 
                            backgroundImage: `url(${process.env.REACT_APP_URL}${props.video.banner})`,
                            height: '680px'}}
                            >
                    <div className={classes.investigation__wrapper__main_bg}/>
                        <div className="g-container">
                            <div className={classes.investigation__wrapper__main__head}>
                                <div className={classes.investigation__wrapper__main__head__left}>
                                    <div className={classes.investigation__wrapper__main__head__left__wrapper}>
                                        <div className={classes.investigation__wrapper__main__head__left__title}>
                                            <h2 className={classes.investigation__wrapper__main__head__left__title_h2}>{props.video.title}</h2>
                                        </div>
                                        <div className={classes.investigation__wrapper__main__head__left_devider}/>
                                        <p className={classes.investigation__wrapper__main__head__left_text}>{props.video.descr}</p>
                                    </div>
                                    <a className={classes.investigation__wrapper__main__head__left__btn} href={`${props.video.link}`} target="_blank" rel="noreferrer">
                                        <div className={classes.investigation__wrapper__main__head__left__btn_primary}>
                                            <YoutubeImg stroke="white" className={classes.investigation__wrapper__main__head__left__btn_primary_img}/>
                                            <p className={classes.investigation__wrapper__main__head__left__btn_primary_text}>Смотреть на YouTube</p>
                                        </div>
                                    </a>
                                    <a className={classes.investigation__wrapper__main__head__left__btn_secondary} href={`${props.video.link}`} target="_blank" rel="noreferrer">
                                        <button className={classes.investigation__wrapper__main__head__left__btn_secondary}>
                                            Смотреть
                                        </button>
                                    </a>
                                </div>
                                <div className={classes.investigation__wrapper__main__head__right}>
                                    {/* <iframe width = '564' height = '372' src={link && `https://www.youtube.com/embed/${link[7]}`} name={props.video.title} frameBorder="0"/> */}
                                    {!toggle ?
                                        <div
                                            className={classes.investigation__wrapperVideoCover}
                                            onClick={() => setToggle(true)}
                                            style={{
                                                backgroundImage: `url(${staticImg})`,
                                                height: '372px',
                                                width: '564px'
                                            }}
                                        >
                                            <PlayVideo className={classes.investigation__wrapperVideoCover_playVideo} />
                                        </div>
                                        :
                                        <div className={classes.investigation__wrapper__main__head__right}>
                                            <iframe 
                                                width='564' 
                                                height='372' 
                                                src={link && `https://www.youtube.com/embed/${link[7]}`} 
                                                name={props.video.title} 
                                                title={props.video.title}
                                                frameBorder="0" 
                                            />
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="g-container">
                        <div className={classes.investigation__wrapper__content}>
                            <div className='ck-content'>
                                <div dangerouslySetInnerHTML={{__html: props.video.content}} className={classes.investigation__wrapper__content_parser}></div>
                            </div>
                        </div>
                    </div>
                    <HelpUs />
                    <Footer />
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    video: state.ItemInvestigation
})
  
export default connect(mapStateToProps, {getOneInvestigation})(PageInvestigation)