import AppReducers from './AppReducers'
import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import ItemInvestigation from './ItemInvestigation';
import BaseCriminals from './BaseCriminals';
import BasePerson from './BasePerson';

const reducers = combineReducers({
        AppReducers: AppReducers,
        ItemInvestigation: ItemInvestigation,
        BaseCriminals: BaseCriminals,
        BasePerson: BasePerson,
})

export const store = createStore(reducers, 
    composeWithDevTools(applyMiddleware(thunk))   
)