import React from "react";
import classes from './MainPage.module.scss'
import {ReactComponent as Youtube} from '../../images/svg/youtubeWhite.svg'
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";


const MainPage = (props) => {

    const navigate = useNavigate();

    const navigateSupport = () => {
        navigate("/support");
    }

    return(
        <section className={classes.mainPage}>
            <div className="g-container">
                <div className={classes.mainPage__wrapper}>
                    <img src={require('../../images/png/blackPeopleMainPage.png')} alt = 'background people' className={classes.mainPage__mainIMG}/>
                    <div className={classes.mainPage__wrapperText}>
                        <h1>БЕЛАРУСКИЙ НАРОДНЫЙ ТРИБУНАЛ</h1>
                        <div className={classes.mainPage__devider}/>
                        <div className={classes.mainPage__descr}>
                            Беларуский Народный Трибунал - это инициатива, созданная для выражения мнения белорусского народа о нарушениях прав человека и свобод в стране. Он проводит заседания с независимыми экспертами, правозащитниками и политиками из разных стран, чтобы привлечь внимание к ситуации в Беларуси и способствовать поиску решений для политического кризиса в стране.
                        </div>
                        <div className={classes.mainPage__descrMobile}>
                            Беларуский Народный Трибунал - это инициатива, созданная для выражения мнения белорусского народа о нарушениях прав человека и свобод в стране.
                        </div>
                    </div>
                    <div className={classes.mainPage__wrapperButtons}>
                        <button className={classes.mainPage__wrapperButtons_button} onClick={navigateSupport}>
                            Поддержать
                        </button>
                        <a className={classes.mainPage__wrapperButtons__youtube} href="https://youtube.com/@tribunal__info"  target="_blank" rel="noreferrer">
                            <Youtube stroke="white" className={classes.mainPage__wrapperButtons__youtube_img}/>
                            <div className={classes.mainPage__wrapperButtons__youtube_text}>YouTube</div>
                        </a>
                    </div>
                    <div className={classes.mainPage__mobileButtons}>
                        <button className={classes.mainPage__mobileButtons_buttonYoutube}> 
                            <a href="https://youtube.com/@tribunal__info" target="_blank" rel="noreferrer" className={classes.mainPage__mobileButtons_button_text}>
                                Смотреть на YouTube
                            </a>
                        </button>
                        <button className={classes.mainPage__mobileButtons_button} onClick={navigateSupport}>                        
                            Поддержать
                        </button>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default MainPage

// const mapStateToProps = (state) => ({
//     values: state.AppReducers
// })

// export default connect(mapStateToProps, {})(MainPage)