import React from "react";
import OneInvestigation from "../OneInvestigation/OneInvestigation";
import classes from './OurInvestigations.module.scss'
import { Link } from "react-router-dom";

const OurInvestigations = (props) => {
    
    return(
        <div className={classes.investigation}>
            <div className="g-container">
                <div className={classes.investigation__wrapper}>
                   <div className={classes.scroll}>
                    {props.userArray.map((el, index) => {
                        return <OneInvestigation 
                            key = {index}
                            path = {`${process.env.REACT_APP_URL}${el.preview_img}`} 
                            title={el.title}
                            id = {el.id}/>
                    })}
                   </div>
                   <button className={classes.investigation__wrapper__button}>
                        <Link to="/support" 
                            className={classes.investigation__wrapper__button_text}
                            onClick={() => props.changeHeaderValue(9)}>
                                Поддержать
                        </Link>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default OurInvestigations