import React from "react";
import classes from './ItemBaseMenuElement.module.scss'

const ItemBaseMenuElement = (props) => {

    return(
        <div className={classes.element} onClick={() => props.onClick()} >
            <div 
                className={props.isActive ? `${classes.element_checkbox} ${classes.element_checkbox_active}` : classes.element_checkbox}/>
            <p  className={classes.element_text}>{props.title}</p>
        </div>
    )
}

export default ItemBaseMenuElement