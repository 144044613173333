import React, {useState} from "react";
import classes from './OneCryptoAddress.module.scss'
import { ReactComponent as CopyCrypto} from "../../images/svg/copyCripto.svg";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const OneCryptoAddress = (props) => {

    const [isFullImg, setFullImg] = useState(false)

    const copyCryptoPath = () => {
        navigator.clipboard.writeText(props.address)
        toast.error(`Номер кошелька ${props.name} скопирован успешно!`, {
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            icon: false,
            progressStyle:{backgroundColor: '#E63946'}
        });
    }

    return(
        <div className={classes.crypto__blockCurrency}>
            <div className={classes.crypto__blockCurrency__item}>
                <div className={classes.crypto__blockCurrency__item_leftPart}>
                    {props.img}
                    <div className={classes.crypto__blockCurrency__item_title}>{props.name}</div>
                    <div className={classes.crypto__blockCurrency__item_subTitle}>{props.lightName}</div>
                </div>
                <div className={classes.crypto__blockCurrency__item__buttons}> 
                    <div className={classes.crypto__blockCurrency__item_address}>{props.address}</div>
                    <CopyCrypto  className={classes.crypto__blockCurrency__item__buttons_img} onClick={copyCryptoPath}/>
                    <img src={props.pathQR} alt='crypto QR' className={classes.crypto__blockCurrency__item__buttons_qr} onClick={() => setFullImg(true)}/>
                    <ToastContainer 
                        position="top-right"
                        autoClose={2000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        closeButton={false}
                    />
                    <div className={isFullImg ? 
                        classes.crypto__blockCurrency__item__buttons_fullScreen : 
                        `${classes.crypto__blockCurrency__item__buttons_fullScreen} 
                        ${classes.crypto__blockCurrency__item__buttons_fullScreen_close}`}
                        onClick={() => setFullImg(false)}
                    >
                        <img src={props.pathQR} alt='crypto QR' className={classes.crypto__blockCurrency__item__buttons_fullScreen_img}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OneCryptoAddress