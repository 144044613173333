import React from "react";
import DeviderBlocks from "../DeviderBlocks/DeviderBlocks";
import classes from './Database.module.scss'
import { Link } from "react-router-dom";

const Database = () => {
    return(
        <div className={classes.database}>
            <div className="g-container">
                <div className={classes.database_title}>
                    <DeviderBlocks span={'Пополняйте '} title={'базу преступников и пострадавших'} />
                </div>
            </div>
            <div className={classes.database__wrapper}>
                <div className="g-container">
                    <div className={classes.database__wrapper__block}>
                        <h2 className={classes.database__wrapper__block_title}>ПРЕСТУПНИКИ</h2>
                        <img src = {require('../../images/png/baseOfCriminals.png')} alt = 'Piece of shit' className={classes.database__wrapper__block_img}/>
                        <div className={classes.database__wrapper__block_text}>
                            Усилиями народа будут собраны сведения о преступлениях и о лицах их совершивших против белорусского народа, его прав и свобод, против белорусской Конституции и белорусских законов. Истинные звериные лица этих "слуг народа" и их преступления должны быть предметом всеобщего обозрения и презрения со стороны белорусского народа и всего человечества.
                        </div>
                        <div className={classes.database__wrapper__block__mobile_header}>
                            База данных лиц совершивших преступления против белорусского народа
                        </div>
                        <div className={classes.database__wrapper__block__mobile_subHeader}>
                            Мы собираем базу данных о нарушениях прав человека и политической репрессии в Беларуси, включая свидетельства, доказательства и информацию о событиях, происходящих в стране.
                        </div>
                        <div className={classes.database__wrapper__block__buttons}>
                            {/* <button className={classes.database__wrapper__block__buttons_fillButton}>Внести преступника</button> */}
                            <Link to='/base' className={classes.database__wrapper__block__buttons_withoutButton}>Смотреть базу</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Database