const TOTAL_RECORDS = 'TOTAL_RECORDS'
const CURRENT_CRIMINALS_RECORDS = 'CURRENT_CRIMINALS_RECORDS'
const TOTAL_CRIMINAL_PAGES = 'TOTAL_CRIMINAL_PAGES'
const CURRENT_PAGE = 'CURRENT_PAGE'
const ARRAY_CRIMINALS = 'ARRAY_CRIMINALS'
const MENU_LIST_CRIMINALS = 'MENU_LIST_CRIMINALS'
const MENU_LIST_CRIMES = 'MENU_LIST_CRIMES'
const ACTIVE_LIST_CRIMINALS = 'ACTIVE_LIST_CRIMINALS'
const ACTIVE_LIST_TYPE_CRIME = 'ACTIVE_LIST_TYPE_CRIME'
const LIST_FILTER_CATEGORY_CRIMINAL = 'LIST_FILTER_CATEGORY_CRIMINAL'
const LIST_FILTER_CATEGORY_CRIMES = 'LIST_FILTER_CATEGORY_CRIMES'
const CURRENT_INPUT_SEARCH_VALUE = 'CURRENT_INPUT_SEARCH_VALUE'
const CURRENT_CRIMINALS_SEARCH_VALUE = 'CURRENT_CRIMINALS_SEARCH_VALUE'
const CURRENT_CRIMES_SEARCH_VALUE = 'CURRENT_CRIMES_SEARCH_VALUE'
const LOADING_BASE = 'LOADING_BASE'

const initialState = {
    totalCriminals: 0,
    currentAmoutCriminals: 0,
    totalPageBase: 0,
    currentPageBase: 0,
    criminalsList: [],
    menuCriminals: [],
    menuTypeCrimes: [],
    activeCriminals: [],
    activeTypeCrime: [],
    filterCategoryCriminals: [],
    filterCategoryCrimes: [],
    currentSearchValue: '',
    currentCriminalsValue: '',
    currentCrimesValue: '',
    isLoadingBase: true,
}

const BaseCriminals = (state = initialState, action) => {
    switch(action.type){
        case TOTAL_RECORDS:
            return{
                ...state,
                totalCriminals: action.payload
            }
        case CURRENT_CRIMINALS_RECORDS:
            return {
                ...state,
                currentAmoutCriminals: action.payload
            }
        case TOTAL_CRIMINAL_PAGES:
            return{
                ...state,
                totalPageBase: action.payload
            }
        case CURRENT_PAGE:
            return{
                ...state,
                currentPageBase: action.payload
            }
        case ARRAY_CRIMINALS:
            return{
                ...state,
                criminalsList: [...action.payload]
            }
        case MENU_LIST_CRIMINALS:
            return{
                ...state,
                menuCriminals: JSON.parse(JSON.stringify(action.payload))
            }
        case MENU_LIST_CRIMES:
            return{
                ...state,
                menuTypeCrimes: JSON.parse(JSON.stringify(action.payload))
            }
        case ACTIVE_LIST_CRIMINALS:
            return{
                ...state,
                activeCriminals: [...action.payload]
            }
        case ACTIVE_LIST_TYPE_CRIME:
            return{
                ...state,
                activeTypeCrime: [...action.payload]
            }
        case LIST_FILTER_CATEGORY_CRIMINAL:
            return {
                ...state,
                filterCategoryCriminals: [...action.payload]
            }
        case LIST_FILTER_CATEGORY_CRIMES:
            return {
                ...state,
                filterCategoryCrimes: [...action.payload]
            }
        case CURRENT_INPUT_SEARCH_VALUE:
            return {
                ...state,
                currentSearchValue: action.payload
            }
        case CURRENT_CRIMINALS_SEARCH_VALUE:
            return {
                ...state,
                currentCriminalsValue: action.payload
            }
        case CURRENT_CRIMES_SEARCH_VALUE:
            return {
                ...state,
                currentCrimesValue: action.payload
            }
        case LOADING_BASE:
            return {
                ...state,
                isLoadingBase: action.payload
            }
        default:
            return state;
    }
}

export const setFilterCriminals = (arr) =>({type: ACTIVE_LIST_CRIMINALS, payload: arr})
export const setfilterTypeCrimes = (arr) =>({type: ACTIVE_LIST_TYPE_CRIME, payload: arr})
export const setLoadingBasePage = () => ({ type: LOADING_BASE, payload: true })

export const getCriminalsBase = () => {
    return async (dispatch) => {
        const video = await fetch(`${process.env.REACT_APP_URL}/api/v1/criminals`);
        const resultVideo = await video.json();
        dispatch({ type: TOTAL_RECORDS, payload: resultVideo.total });
        dispatch({ type: CURRENT_CRIMINALS_RECORDS, payload: resultVideo.records.length });
        dispatch({ type: TOTAL_CRIMINAL_PAGES, payload: resultVideo.last_page});
        dispatch({ type: CURRENT_PAGE, payload: resultVideo.page});
        dispatch({ type: ARRAY_CRIMINALS, payload: resultVideo.records});
        dispatch({ type: LOADING_BASE, payload: false });
    }
}

export const getBaseFirstFilter = () => {
    return async (dispatch) => {
        const filter = await fetch(`${process.env.REACT_APP_URL}/api/v1/criminals/person-categories`);
        const res = await filter.json();
        dispatch({ type: MENU_LIST_CRIMINALS, payload: res.results});
    }
}

export const getBaseSecondFilter = () => {
    return async (dispatch) => {
        const filter = await fetch(`${process.env.REACT_APP_URL}/api/v1/criminals/criminal-categories`);
        const res = await filter.json();
        dispatch({ type: MENU_LIST_CRIMES, payload: res.results});
    }
}

export const findBaseFilterAndInput = (input, crime, reason) => {
    return async (dispatch) => {
        const criminals = crime.join(',')
        const type = reason.join(',')
        let str = `${process.env.REACT_APP_URL}/api/v1/criminals/?search=${input}`
        if(criminals && type) str += `&personCategories=${criminals}&criminalCategories=${type}`
        else if(criminals) str += `&personCategories=${criminals}`
        else if(type) str += `&criminalCategories=${type}`
        const filter = await fetch(str);
        const resultVideo = await filter.json();
        dispatch({ type: CURRENT_CRIMINALS_RECORDS, payload: resultVideo.records.length });
        dispatch({ type: TOTAL_CRIMINAL_PAGES, payload: resultVideo.last_page });
        dispatch({ type: CURRENT_PAGE, payload: resultVideo.page });
        dispatch({ type: ARRAY_CRIMINALS, payload: resultVideo.records });
        dispatch({ type: CURRENT_INPUT_SEARCH_VALUE, payload: input });
        dispatch({ type: CURRENT_CRIMINALS_SEARCH_VALUE, payload: criminals });
        dispatch({ type: CURRENT_CRIMES_SEARCH_VALUE, payload: type });
        dispatch({ type: LOADING_BASE, payload: false });
    }
}

export const changeActiveBasePagination = (num) => {
    return async(dispatch, getState) => {
        const currentState = getState()
        let str = ''
        if (currentState.BaseCriminals.currentSearchValue) str += `&search=${currentState.BaseCriminals.currentSearchValue}`
        if (currentState.BaseCriminals.currentCriminalsValue) str += `&personCategories=${currentState.BaseCriminals.currentCriminalsValue}`
        if (currentState.BaseCriminals.currentCrimesValue) str += `&criminalCategories=${currentState.BaseCriminals.currentCrimesValue}`
        const array = await fetch(`${process.env.REACT_APP_URL}/api/v1/criminals/?page=${num}${str}`);
        const anotherOne = await array.json();
        dispatch({ type: CURRENT_PAGE, payload: anotherOne.page});
        dispatch({ type: ARRAY_CRIMINALS, payload: anotherOne.records});
        dispatch({ type: LOADING_BASE, payload: false });
    }
}


export default BaseCriminals