import React from "react"
import { connect } from "react-redux"
import Database from "../../components/Database/Database"
import FAQ from "../../components/FAQ/FAQ"
import Footer from "../../components/Footer/Footer"
import Header from "../../components/Header/Header"
import HelpUs from "../../components/HelpUs/HelpUs"
import MainPage from "../../components/MainPage/MainPage"
import OurInvestigations from "../../components/OurInvestigations/OurInvestigations"
import OurProjects from "../../components/OurProjects/OurProjects"
import SocialSites from "../../components/SocialSites/SocialSites"

const HomePage = (props) => {

    return (
        <div className='g-wrapperPage'>
            <Header path = {1}/>
            <MainPage />
            <OurProjects />
            <OurInvestigations userArray = {props.data.videoInvestigations}/>
            <Database />
            <HelpUs />
            <SocialSites />
            <FAQ />
            <Footer />
        </div>
    )
}

const mapStateToProps = (state) => ({
    data: state.AppReducers
})
  
export default connect(mapStateToProps, {})(HomePage)