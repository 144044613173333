// import './index'
import React from 'react'
import classes from './ItemBasePersonSocialSites.module.scss'

const ItemBasePersonSocialSites = (props) => {
    return(
        <a href={props.path} target="_blank" rel="noreferrer" className={classes.link}>{props.title}</a>
    )
}

export default ItemBasePersonSocialSites