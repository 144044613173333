import React from "react";
import classes from './OneInvestigation.module.scss'
import { Link } from "react-router-dom";

const OneInvestigation = (props) => {

    const path = `/investigations/${props.id}`

    return(
        <Link className={classes.item} to = {path}>
            <img src={props.path} alt='project' className={classes.item_img}/>
            <div className={classes.item_title}>{props.title}</div>
        </Link>
    )
}

export default OneInvestigation