import React, { useState } from "react";
import classes from './ElementFaq.module.scss'

const ElementFaq = (props) => {
    const [isFullFaq, setFullFaq] = useState(false)
    const handleArrow = () => {
        setFullFaq(!isFullFaq)
    }
    return(
        <div className={classes.faq__block}>
            <div className={classes.faq__block__item}>
                <div className={ `${classes.faq__block__item_titleRight} ${isFullFaq ? classes.faq__block__item_titleRight : classes.faq__block__item_titleBottom}`} onClick = {handleArrow}>{props.title}</div>
                { isFullFaq && <div className={classes.faq__block__item_descr}>{props.descr}</div> }
            </div>
        </div>
    )
}

export default ElementFaq