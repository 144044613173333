import React from "react";
import DeviderBlocks from "../DeviderBlocks/DeviderBlocks";
import OneCryptoAddress from "../OneCryptoAddress/OneCryptoAddress";
import classes from './HelpUs.module.scss'
import { ReactComponent as ImgBtc} from "../../images/svg/imgBtc.svg";
import { ReactComponent as ImgUsdt} from "../../images/svg/imgUsdt.svg";
import { ReactComponent as ImgEth} from "../../images/svg/imgEth.svg";
import { ReactComponent as ImgLtc} from "../../images/svg/imgLtc.svg";
import { ReactComponent as ImgSol} from "../../images/svg/SOL.svg";

const HelpUs = () => {
    
    return(
        <div className={classes.donate}>
            <div className="g-container">
                <DeviderBlocks span={'Поддержите '} title = {'команду БНТ'}/>
                <div className={classes.donate__wrapper}>
                    {/* <div className={classes.donate__wrapper__money}>
                        <div className={classes.donate__wrapper__money__wrapperDevider}>
                            <div className={classes.donate__wrapper__money__wrapperDevider_devider}/>
                            <div className={classes.donate__wrapper__money__wrapperDevider_devider}/>
                            <div className={classes.donate__wrapper__money__wrapperDevider_devider}/>
                        </div>
                        <div className={classes.donate__wrapper__money_title}>Информация</div>
                        <div className={classes.donate__wrapper__money__wrapperAmount}>
                            <div className={classes.donate__wrapper__money__wrapperAmount_amount}>10€</div>
                            <div className={classes.donate__wrapper__money__wrapperAmount_amount}>20€</div>
                            <div className={classes.donate__wrapper__money__wrapperAmount_amount}>50€</div>
                            <div className={classes.donate__wrapper__money__wrapperAmount_amount}>100€</div>
                            <div className={classes.donate__wrapper__money__wrapperAmount_amount}>200€</div>
                        </div>
                        <div className={classes.donate__wrapper__money__wrapperInput}>
                            <input placeholder="Выберите любую сумму €" className={classes.donate__wrapper__money__wrapperInput_input}/>
                        </div>
                        <button className={classes.donate__wrapper__money_button}>Далее</button>
                    </div> */}
                    <div className={classes.donate__wrapper__crypto}>
                        <div className={classes.donate__wrapper__crypto_devider}/>
                        <div className={classes.donate__wrapper__crypto_title}>Поддержать в крипте</div>
                        <OneCryptoAddress 
                            img={<ImgBtc className={classes.donate__wrapper__crypto_img}/>}
                            name={'BTC'}
                            lightName={'BITCOIN'}
                            address={'bc1qqj5tnxpd2yza25a8328h2ymp320h5w2jc7fq4q'}
                            pathQR={require('../../images/jpg/QR_BTC.jpg')}
                        />
                        <OneCryptoAddress 
                            img={<ImgUsdt className={classes.donate__wrapper__crypto_img}/>}
                            name={'USDT'}
                            lightName={'TRC20'}
                            address={'TEZPywm7NGn7h3wVVLpd5d76kFQ7gUEhMk'}
                            pathQR={require('../../images/jpg/QR_USDT.jpg')}
                        />
                        <OneCryptoAddress 
                            address={'0x15A8C4Eb78486D9f0eA06dbc68344dfDCF4D015F'}
                            img={<ImgEth className={classes.donate__wrapper__crypto_img}/>}
                            name={'Etherium'}
                            lightName={'ERC20'}
                            pathQR={require('../../images/jpg/QR_ETH.jpg')}
                        />
                        <OneCryptoAddress 
                            address={'ltc1qy9llsmmr3zfxfm4jvtyvlsreapg9lxvjgpfpwa'}
                            img={<ImgLtc className={classes.donate__wrapper__crypto_img}/>}
                            name={'Litecoin'}
                            lightName={'LTC'}
                            pathQR={require('../../images/jpg/QR_LTC.jpg')}
                        />
                        <OneCryptoAddress 
                            address={'9skVBdAb6UdvC8br2NosdwriuSGDWBVtje4BEQ5ziWw1'}
                            img={<ImgSol className={classes.donate__wrapper__crypto_img}/>}
                            name={'Solana'}
                            lightName={'SOL'}
                            pathQR={require('../../images/jpg/QR_SOL.jpg')}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HelpUs