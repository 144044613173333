import React, {useEffect} from "react";
import { connect } from "react-redux"
import classes from './About.module.scss'
import Header from "../../components/Header/Header";
import flags from '../../images/jpg/bel-ukr flags.png'
import photoUs from '../../images/jpg/aboutUs_learnUs.webp'
import Footer from "../../components/Footer/Footer";
import HelpUs from "../../components/HelpUs/HelpUs";
import DeviderBlocks from "../../components/DeviderBlocks/DeviderBlocks";
import { getListMediaMention } from "../../bll/AppReducers";

const About = (props) => {

    useEffect(() => {
        window.scrollTo(0, 0);
        props.getListMediaMention()
    },[])

    return(
        <section className={classes.about}>
            <div className='g-wrapperPage'>
                <Header path = {4}/>
                <div className={classes.about__wrapper}>
                    <div className={classes.about__wrapper__main}>
                        <div className="g-container">
                            <div className={classes.about__wrapper__main__head}>
                                <div className={classes.about__wrapper__main__head__title}>
                                    <h2 className={classes.about__wrapper__main__head__title_h2}>БЕЛАРУСКИЙ НАРОДНЫЙ ТРИБУНАЛ</h2>
                                    <img src={flags} className={classes.about__wrapper__main__head__title_img} alt="flags belarusian and ukranian"/>
                                </div>
                                <div className={classes.about__wrapper__main__head_devider}/>
                                <p className={classes.about__wrapper__main__head_text}>Мы — команда небезразличных людей, которые не могут спокойно смотреть
                                    на то беззаконие, которое происходит в нашей стране.</p>
                                <p className={classes.about__wrapper__main__head_text}>Донатам и своей поддержкой вы помогаете серверу и команде!
                                    Мы ценим каждого, кто уже оставил свое пожертвование. Это очень, очень много значит для нас!</p>
                            </div>
                        </div>
                    </div>
                    <div className="g-container">
                        <div className={classes.about__wrapper__about}>
                            <div className={classes.about__wrapper__about__text}>
                               <DeviderBlocks span={'Узнайте '} title = {'кто мы такие'}/>
                               <p className={classes.about__wrapper__about__text_descr}>
                                    Беларуский Народный Трибунал занимается сбором данных о нарушениях прав человека в Беларуси, включая случаи насилия и произвольных задержаний, а также выражает мнение белорусского народа о политической ситуации в стране. Команда БНТ состоит из независимых экспертов, правозащитников, политиков и общественных деятелей из разных стран, которые проводят онлайн-заседания, чтобы привлечь внимание к ситуации в Беларуси и найти решения для политического кризиса в стране.
                               </p>
                            </div>
                            <img src={photoUs} className={classes.about__wrapper__about_img} alt="who we are"/>
                        </div>
                    </div>
                    <HelpUs />
                    <div className="g-container">
                        <div className={classes.about__wrapper__notice}>
                            <DeviderBlocks span={'Упоминания '} title = {'в СМИ'}/>
                            <div className={classes.about__wrapper__notice__wrap}>
                                {props.state.mediaMention.map((media) => {
                                    return (
                                        <a 
                                            className={classes.about__wrapper__notice__wrap_component} 
                                            href={media.link}
                                            target="_blank" 
                                            rel="noreferrer"
                                            key={media.id}
                                        >
                                            <img 
                                                className={classes.about__wrapper__notice__wrap_component_img}
                                                src={`${process.env.REACT_APP_URL}${media.logo}`}
                                                alt="media logo"
                                            />
                                            <p className={classes.about__wrapper__notice__wrap_component_text}>{media.title}</p>
                                        </a>
                                    )
                                })}
                            </div>
                        </div>
                        <div className={classes.about__wrapper__partners}>
                            <DeviderBlocks span={''} title = {'Организации партнеры'}/>
                            <div className={classes.about__wrapper__partners__wrap}>
                                <a 
                                    className={classes.about__wrapper__partners__wrap_component}
                                    href="https://www.iri.org"
                                    target="_blank" 
                                    rel="noreferrer"
                                >
                                    <img src={require('../../images/jpg/partner1.jpg')} alt='Партнер ИРИ' className={classes.about__wrapper__partners__wrap_component_img}/>
                                </a>
                                                                <a 
                                    className={classes.about__wrapper__partners__wrap_component}
                                    href="https://www.usaid.gov"
                                    target="_blank" 
                                    rel="noreferrer"
                                >
                                    <img src={require('../../images/jpg/partner2.jpg')} alt='Партнер ИРИ' className={classes.about__wrapper__partners__wrap_component_img}/>
                                </a>
                                                                <a 
                                    className={classes.about__wrapper__partners__wrap_component}
                                    href="https://www.youtube.com/channel/UC5qVnVL_95Ws1L6exdrbghw"
                                    target="_blank" 
                                    rel="noreferrer"
                                >
                                    <img src={require('../../images/jpg/partner3.jpg')} alt='Партнер ИРИ' className={classes.about__wrapper__partners__wrap_component_img}/>
                                </a>
                                                                <a 
                                    className={classes.about__wrapper__partners__wrap_component}
                                    href="https://investigatebel.org/ru"
                                    target="_blank" 
                                    rel="noreferrer"
                                >
                                    <img src={require('../../images/jpg/partner4.jpg')} alt='Партнер ИРИ' className={classes.about__wrapper__partners__wrap_component_img}/>
                                </a>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </section>
    )
}

const mapStateToProps = (state) => ({
    state: state.AppReducers
})

export default connect(mapStateToProps, { getListMediaMention })(About)