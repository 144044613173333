import React, {useState} from "react";
import classes from './ItemCriminalPersson.module.scss'
import { Link } from "react-router-dom";
import moment from "moment";

const ItemCriminalPersson = (props) => {

    const [bg] = useState(`${process.env.REACT_APP_URL}${props.el.photo}`)
    const path = `/base/${props.el.id}`
    const dateOfBirth = moment(props.el?.date_of_birth)?.format('DD.MM.YYYY')

    return(
        <Link className={classes.wrapper} to={path}>
            <Link className={classes.person} style={{ backgroundImage: `url(${bg})` }} to={path}>
                <div className={classes.person__wrapper}>
                    <p className={classes.person__wrapper_surname}>{props.el.last_name}</p>
                    <p className={classes.person__wrapper_name}>{props.el.first_name}</p>
                </div>
                <div className={classes.person_devider} />
            </Link>
            <div className={classes.wrapper__mobile}>
                <div className={classes.wrapper__mobile__name}>
                    <p className={classes.wrapper__mobile__name_surname}>{props.el.last_name}</p>
                    <p className={classes.wrapper__mobile__name_name}>{props.el.first_name}</p>
                    {/* <p className={classes.person__wrapper_name}>{props.el.middle_name}</p> */}
                </div>
                <div className={classes.wrapper__mobile__date}>
                    <p>{dateOfBirth}</p>
                </div>
                <div className={classes.wrapper__mobile__duty}>
                    <p>{props.el.position}</p>
                </div>
                <div className={classes.wrapper__mobile__wrapperArrow_arrowRight}>
                    <div className={classes.wrapper__mobile_arrowRight} />
                </div>
            </div>
        </Link>
    )
}

export default ItemCriminalPersson