import React, {useEffect} from "react";
import classes from './Base.module.scss'
import Header from "../../components/Header/Header";
import flags from '../../images/jpg/bel-ukr flags.png'
import solidarity from '../../images/png/bel-ukr-solidarity.png'
import Footer from "../../components/Footer/Footer";
import { connect } from "react-redux"
import { getCriminalsBase, getBaseFirstFilter, getBaseSecondFilter } from "../../bll/BaseCriminals";
import BaseLeftMenu from "../../components/BaseLeftMenu/BaseLeftMenu";
import BaseMainInfo from "../../components/BaseMainInfo/BaseMainInfo";

const Base = (props) => {

    useEffect(() => {
        props.getCriminalsBase()
        props.getBaseFirstFilter()
        props.getBaseSecondFilter()
        window.scrollTo(0, 0);
    },[])

    const width = window.screen.width

    return(
        <div className={classes.base}>
            <div className='g-wrapperPage'>
                <Header path = {3}/>
                <div className={classes.base__wrapper}>
                    <div className={classes.base__wrapper__main}>
                        <div className="g-container">
                            <div className={classes.base__wrapper__main__head}>
                                <img src={flags} className={classes.base__wrapper__main__head__title_img} alt="flags belarusian and ukranian"/>
                                <img src={solidarity} className={classes.base__wrapper__main__head__title_solidarity} alt="belarusian and ukranian solidarity"/>
                                <div className={classes.base__wrapper__main__head__title}>
                                    <h2 className={classes.base__wrapper__main__head__title_h2}>БАЗА ПРЕСТУПНИКОВ РЕЖИМА ЛУКАШЕНКО</h2>
                                </div>
                                <div className={classes.base__wrapper__main__head_devider}/>
                                <p className={classes.base__wrapper__main__head_text}>Мы собрали единый архив данных преступников и доказательств преступлений. Нашей задачей является сбор материалов проверки по заявлениям о преступлениях, которые будут использованы будущими белорусскими судебными органами и международными правовыми организациями для вынесения справедливого приговора и подготовки уголовных дел в рамках белорусского и международного права.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="g-container">
                        <div className={classes.base__wrapper__content}>
                            <BaseLeftMenu />     
                            <BaseMainInfo /> 
                        </div>
                    </div>
                    {width > 1000 && <Footer />}
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    base: state.BaseCriminals
})
  
export default connect(mapStateToProps, { getCriminalsBase, getBaseFirstFilter, getBaseSecondFilter })(Base)