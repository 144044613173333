// export const URL = 'http://192.168.0.131'

const CHANGE_HEADER = 'CHANGE_HEADER'
const SET_TOTAL_VIDEO = 'SET_TOTAL_VIDEO'
const SET_TOTAL_PAGE = 'SET_TOTAL_PAGE'
const CURRENT_PAGE = 'CURRENT_PAGE'
const VIDEO_INVESTIGETIONS = 'VIDEO_INVESTIGETIONS'
const LATEST_INVESTIGATION_VIDEO = 'LATEST_INVESTIGATION_VIDEO'
const LIST_MEDIA_MENTION = 'LIST_MEDIA_MENTION'

const initialState = {
    header: 1,
    totalVideo: 0,
    totalPage: 0,
    currentPage: 0,
    videoInvestigations: [],
    lastInvestigation: {},
    mediaMention: [],
}

const AppReducers = (state = initialState, action) => {
    switch(action.type){
        case CHANGE_HEADER:
            return{
                ...state,
                header: action.number
            }
        case SET_TOTAL_VIDEO:
            return {
                ...state,
                totalVideo: action.payload
            }
        case SET_TOTAL_PAGE:
            return {
                ...state,
                totalPage: action.payload
            }
        case CURRENT_PAGE:
            return {
                ...state,
                currentPage: action.payload
            }
        case VIDEO_INVESTIGETIONS:
            return {
                ...state,
                videoInvestigations: [...action.payload]
            }
        case LATEST_INVESTIGATION_VIDEO:
            return {
                ...state,
                lastInvestigation: {...action.payload}
            }
        case LIST_MEDIA_MENTION:
            return {
                ...state,
                mediaMention: [...action.payload]
            }
        default:
            return state;
    }
}

export const changeHeaderValue = (number) =>({type: CHANGE_HEADER, number})
export const changeActivePagination = (number) => {
    return async (dispatch) => {
        const array = await fetch(`${process.env.REACT_APP_URL}/api/v1/investigations/?page=${number}`);
        const anotherOne = await array.json();
        dispatch({ type: SET_TOTAL_VIDEO, payload: anotherOne.total});
        dispatch({ type: SET_TOTAL_PAGE, payload: anotherOne.last_page});
        dispatch({ type: CURRENT_PAGE, payload: anotherOne.page});
        dispatch({ type: VIDEO_INVESTIGETIONS, payload: anotherOne.records});
    }
}
export const getInitRequest = () => {
    return async (dispatch) => {
        const array = await fetch(`${process.env.REACT_APP_URL}/api/v1/investigations`);
        const anotherOne = await array.json();
        dispatch({ type: SET_TOTAL_VIDEO, payload: anotherOne.total});
        dispatch({ type: SET_TOTAL_PAGE, payload: anotherOne.last_page});
        dispatch({ type: CURRENT_PAGE, payload: anotherOne.page});
        dispatch({ type: VIDEO_INVESTIGETIONS, payload: anotherOne.records});
        dispatch({ type: LATEST_INVESTIGATION_VIDEO, payload: anotherOne.records[0]});
    }
}
export const getListMediaMention = () => {
    return async (dispatch) => {
        const array = await fetch(`${process.env.REACT_APP_URL}/api/v1/crm/press/?limit=4`);
        const anotherOne = await array.json();
        dispatch({ type: LIST_MEDIA_MENTION, payload: anotherOne.records});
    }
}

export default AppReducers