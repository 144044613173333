const ID_BASE = 'ID_BASE'
const PHOTO_BASE = 'PHOTO_BASE'
const NAME_BASE = 'NAME_BASE'
const SURNAME_BASE = 'SURNAME_BASE'
const MIDDLENAME_BASE = 'MIDDLENAME_BASE'
const BIRTH_BASE = 'BIRTH_BASE'
const ADDRESS_BASE = 'ADDRESS_BASE'
const JOB_BASE = 'JOB_BASE'
const NUMBER_BASE = 'NUMBER_BASE'
const SOCIAL_BASE = 'SOCIAL_BASE'
const CONTENT_BASE = 'CONTENT_BASE'

const initialState = {
    id: '',
    photo: '',
    name: '',
    surname: '',
    middlename: '',
    birth: '',
    address: '',
    job: '',
    number: '',
    social: [],
    content: '',
}

const BasePerson = (state = initialState, action) => {
    switch(action.type){
        case ID_BASE:
            return{
                ...state,
                id: action.payload
            }
        case PHOTO_BASE:
            return{
                ...state,
                photo: action.payload
            }
        case NAME_BASE:
            return{
                ...state,
                name: action.payload
            }
        case SURNAME_BASE:
            return{
                ...state,
                surname: action.payload
            }        
        case MIDDLENAME_BASE:
            return{
                ...state,
                middlename: action.payload
            }
        case BIRTH_BASE:
            return{
                ...state,
                birth: action.payload
            }
        case ADDRESS_BASE:
            return{
                ...state,
                address: action.payload
            }
        case JOB_BASE:
            return{
                ...state,
                job: action.payload
            }
        case NUMBER_BASE:
            return{
                ...state,
                number: action.payload
            }
        case SOCIAL_BASE:
            return{
                ...state,
                social: [...action.payload]
            }
        case CONTENT_BASE:
            return{
                ...state,
                content: action.payload
            }
        default:
            return state;
    }
}

// export const setFilterCriminals = (arr) =>({type: ACTIVE_LIST_CRIMINALS, payload: arr})

export const getInfoAboutPerson = (id) => {
    return async (dispatch) => {
        const video = await fetch(`${process.env.REACT_APP_URL}/api/v1/criminals/${id}`);
        const resultVideo = await video.json();
        dispatch({ type: ID_BASE, payload: resultVideo.id});
        dispatch({ type: PHOTO_BASE, payload: resultVideo.photo});
        dispatch({ type: NAME_BASE, payload: resultVideo.first_name});
        dispatch({ type: SURNAME_BASE, payload: resultVideo.last_name});
        dispatch({ type: MIDDLENAME_BASE, payload: resultVideo.middle_name});
        dispatch({ type: BIRTH_BASE, payload: resultVideo.date_of_birth});
        dispatch({ type: ADDRESS_BASE, payload: resultVideo.address});
        dispatch({ type: JOB_BASE, payload: resultVideo.position});
        dispatch({ type: NUMBER_BASE, payload: resultVideo.individual_number});
        dispatch({ type: SOCIAL_BASE, payload: resultVideo.social_medias});
        dispatch({ type: CONTENT_BASE, payload: resultVideo.content});
    }
}

export default BasePerson