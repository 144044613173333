import React, {useMemo, useEffect} from "react";
import classes from './Pagination.module.scss'
import { connect } from "react-redux";
import { changeActivePagination } from "../../bll/AppReducers";

const Pagination = (props) => {

    const pages = []

    for(let i = 1; i <= props.data.totalPage; i++) {
        pages.push(i)
    }

    const onClick = (num) => {
        window.scrollTo(0, 650);
        props.changeActivePagination(num)
    }

    return (
        <div className={classes.pagination}>
            {pages.map((el,index) => {
                return <div 
                    key={index} 
                    className={el === props.data.currentPage ? classes.pagination_active : classes.pagination_number}
                    onClick = {() => onClick(el)}>
                        {el}
                </div>
            })}
        </div>
    )
}

const mapStateToProps = (state) => ({
    data: state.AppReducers
})
  
export default connect(mapStateToProps, {changeActivePagination})(Pagination)