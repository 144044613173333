import React, {useState} from "react";
import classes from './Header.module.scss'
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {ReactComponent as Youtube} from '../../images/svg/youtubeHeader.svg'
import {ReactComponent as Share} from '../../images/svg/shareButton.svg'
// import {ReactComponent as ShareFacebook} from '../../images/svg/shareFacebook.svg'
import {ReactComponent as ShareInstagram} from '../../images/svg/shareInstagram.svg'
import {ReactComponent as ShareYoutube} from '../../images/svg/shareYoutube.svg'
import {ReactComponent as ShareTelegram} from '../../images/svg/shareTelegram.svg'
import {ReactComponent as Close} from '../../images/svg/close.svg'
import {ReactComponent as Logo} from '../../images/svg/logo.svg'
import {ReactComponent as ShareCopy} from '../../images/svg/shareCopy.svg'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Header = (props) => {

    const navigate = useNavigate();

    const [showShare, setShowShare] = useState(false)
    const shareYoutube = 'https://youtube.com/@tribunal__info'
    const shareInstagram = 'http://Instagram.com/narodnytribunal'
    const shareTelegram = 'https://t.me/tribunal_info'

    const [isBurgerMenu, setBurgerMenu] = useState(true)

    const handleBurger = () => {
        const $body = document.querySelector('body');
        let scrollPosition = 0;
        if(isBurgerMenu){
            scrollPosition = window.pageYOffset;
            document.body.style.overflow = "hidden"
            $body.style.position = 'fixed';
            $body.style.top = `-${scrollPosition}px`;
            $body.style.width = '100%';
            //-webkit-overflow-scrolling: touch;
        }
        else{
            document.body.style.overflow = "visible"
            $body.style.removeProperty('position');
            $body.style.removeProperty('top');
            $body.style.removeProperty('width');
            window.scrollTo(0, scrollPosition);
        }
        setBurgerMenu(prev => !prev)
    }

    const navigateSupport = () => {
        navigate("/support");
    }

     const copyCryptoPath = () => {
        navigator.clipboard.writeText('https://tribunal.info')
        toast.error(`Адрес сайта скопирован успешно!`, {
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            icon: false,
            progressStyle:{backgroundColor: '#E63946'}
        });
    }

    return(
        <section className={classes.header}>
            <div className="g-container">
                <div className={classes.header__wrapper}>
                    <nav className={classes.header__wrapperLinks}>
                        <Link to="/" onClick={() => props.changeHeaderValue(1)}>
                            <Logo fill="#121212" className={classes.header__mainLogo}/>
                        </Link>
                        <ul className={classes.header__styleLinks}>
                            <li className={classes.header__styleLinks_link}>
                                <Link to="/" 
                                    className={props.path === 1 ? classes.header__styleLinks_link_text_active : classes.header__styleLinks_link_text}
                                    onClick={() => props.changeHeaderValue(1)}>БНТ</Link>
                            </li>
                            <li className={classes.header__styleLinks_link}>
                                <Link to="/investigations" 
                                    className={props.path === 2 ? classes.header__styleLinks_link_text_active : classes.header__styleLinks_link_text}
                                    onClick={() => props.changeHeaderValue(2)}>РАССЛЕДОВАНИЯ</Link>
                            </li>
                            <li className={classes.header__styleLinks_link}>
                                <Link to="/base" 
                                    className={props.path === 3 ? classes.header__styleLinks_link_text_active : classes.header__styleLinks_link_text }
                                    onClick={() => props.changeHeaderValue(3)}>БАЗА</Link>
                            </li>
                            <li className={classes.header__styleLinks_link}>
                                <Link to="/about" 
                                    className={props.path === 4 ? classes.header__styleLinks_link_text_active : classes.header__styleLinks_link_text}
                                    onClick={() => props.changeHeaderValue(4)}>О ПРОЕКТЕ</Link>
                            </li>
                        </ul>
                    </nav>
                    <div className={classes.header__wrapperButtons}>
                        <a href={shareYoutube} target="_blank" rel="noreferrer">
                            <Youtube stroke="#212121" className={classes.header__wrapperButtons_youtube}/>
                        </a>
                        <div className={classes.header__wrapperButtons__popup} onClick={() => setShowShare(true)}>
                            <Share stroke="black" className={classes.header__wrapperButtons__popup_share}/>
                        </div>
                        {/* <button className={classes.header__styleLinks_link_btn} onClick={navigateSupport}> */}
                            <Link to="/support" 
                                className={classes.header__styleLinks_link_btn}
                                onClick={() => props.changeHeaderValue(9)}>Поддержать</Link>
                        {/* </button> */}
                    </div>
                    {showShare && 
                        <div className={classes.header__wrapper__share}>
                            <div className={classes.header__wrapper__share__wrapper}>
                                <p className={classes.header__wrapper__share__wrapper_title}><span>Следите</span> за нами в соц. сетях</p>
                                <div className={classes.header__wrapper__share__wrapper__images}>
                                    {/* <ShareFacebook 
                                        fill="#4267B2" 
                                        className={`${classes.header__wrapper__share__wrapper__images_img} 
                                        ${classes.header__wrapper__share__wrapper__images_img_facebook}`}/> */}
                                    <a href={shareInstagram} target="_blank" rel="noreferrer" className={classes.header__wrapper__share__wrapper__images_container}>
                                        <ShareInstagram 
                                            fill="#E05D73" 
                                            className={`${classes.header__wrapper__share__wrapper__images_img} 
                                            ${classes.header__wrapper__share__wrapper__images_img_instagram}`}
                                        />
                                    </a>
                                    <a href={shareYoutube} target="_blank" rel="noreferrer" className={classes.header__wrapper__share__wrapper__images_container}>
                                        <ShareYoutube 
                                            fill="#E63946" 
                                            className={`${classes.header__wrapper__share__wrapper__images_img} 
                                            ${classes.header__wrapper__share__wrapper__images_img_youtube}`}
                                        />
                                    </a>
                                    <a href={shareTelegram} target="_blank" rel="noreferrer" className={classes.header__wrapper__share__wrapper__images_container}>
                                        <ShareTelegram 
                                            fill="#29A3EF" 
                                            className={`${classes.header__wrapper__share__wrapper__images_img} 
                                            ${classes.header__wrapper__share__wrapper__images_img_telegram}`}
                                        />
                                    </a>
                                    <ShareCopy
                                        fill="#F4F4F4" 
                                        className={`${classes.header__wrapper__share__wrapper__images_img} 
                                        ${classes.header__wrapper__share__wrapper__images_img_copy}`}
                                        onClick={copyCryptoPath}/>
                                    <ToastContainer 
                                        position="top-right"
                                        autoClose={2000}
                                        hideProgressBar={false}
                                        newestOnTop={false}
                                        closeOnClick
                                        rtl={false}
                                        pauseOnFocusLoss
                                        draggable
                                        pauseOnHover
                                        closeButton={false}
                                    />
                                </div>
                            </div>
                            <Close className={classes.header__wrapper__share_close} fill="#121212" onClick={() => setShowShare(false)}/>
                        </div>
                    }
                </div>
            </div>
            {isBurgerMenu &&
                <div className={classes.header__mobileWrapper}>
                    <div className={classes.header__mobileWrapper__leftPart}>
                        <Link to='/'>
                            <Logo fill="#121212" className={classes.header__mobileWrapper__leftPart_logo} />
                        </Link>
                        <p className={classes.header__mobileWrapper__leftPart_title}>TRIBUNAL.INFO</p>
                    </div>
                    <div className={classes.header__mobileWrapper__rightPart__wrapper} onClick={handleBurger}>
                        <div className={classes.header__mobileWrapper__rightPart}>
                            <span />
                        </div>
                    </div>
                </div>
            }
            {!isBurgerMenu && 
                <div className={classes.header__burgerMenu}>
                    <div className={classes.header__burgerMenu_close} onClick = {handleBurger}>
                        <span />
                    </div>
                    <div className={classes.header__burgerMenu__wrapper}>
                        <div className={classes.header__burgerMenu__wrapper_upperSide}>
                            <h2 className={classes.header__burgerMenu__wrapper_title}>TRIBUNAL.INFO</h2>
                            <nav className={classes.header__burgerMenu__wrapper__nav}>
                                <ul className={classes.header__burgerMenu__wrapper__nav__styleLinks}>
                                    <li className={classes.header__burgerMenu__wrapper__nav__styleLinks_link}>
                                        <Link to="/" 
                                            className={props.path === 1 ? classes.header__burgerMenu__wrapper__nav__styleLinks_link_text_active : classes.header__burgerMenu__wrapper__nav__styleLinks_link_text}
                                            onClick={() => props.changeHeaderValue(1)}>БНТ
                                        </Link>
                                    </li>
                                    <li className={classes.header__burgerMenu__wrapper__nav__styleLinks_link}>
                                        <Link to="/investigations" 
                                            className={props.path === 2 ? classes.header__burgerMenu__wrapper__nav__styleLinks_link_text_active : classes.header__burgerMenu__wrapper__nav__styleLinks_link_text}
                                            onClick={() => props.changeHeaderValue(2)}>РАССЛЕДОВАНИЯ
                                        </Link>
                                    </li>
                                    <li className={classes.header__burgerMenu__wrapper__nav__styleLinks_link}>
                                        <Link to="/base" 
                                            className={props.path === 3 ? classes.header__burgerMenu__wrapper__nav__styleLinks_link_text_active : classes.header__burgerMenu__wrapper__nav__styleLinks_link_text}
                                            onClick={() => props.changeHeaderValue(3)}>БАЗА
                                        </Link>
                                    </li>
                                    <li className={classes.header__burgerMenu__wrapper__nav__styleLinks_link}>
                                        <Link to="/about" 
                                            className={props.path === 4 ? classes.header__burgerMenu__wrapper__nav__styleLinks_link_text_active : classes.header__burgerMenu__wrapper__nav__styleLinks_link_text}
                                            onClick={() => props.changeHeaderValue(4)}>О ПРОЕКТЕ
                                        </Link>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                        <div className={classes.header__burgerMenu__wrapper_downSide}>
                            {/* <div className={classes.header__burgerMenu__wrapper__socialLinks}>
                                <a href="https://youtube.com/@tribunal__info"  target="_blank" rel="noreferrer">
                                    <Youtube stroke="red" className={classes.header__burgerMenu__wrapper__socialLinks_youtube}/>
                                </a>
                                <a href="https://youtube.com/@tribunal__info"  target="_blank" rel="noreferrer">
                                    <Youtube stroke="red" className={classes.header__wrapperButtons_youtube}/>
                                </a>
                            </div> */}
                            <Link to="/support" 
                                className={classes.header__burgerMenu__wrapper_downSide_btn}
                                onClick={() => props.changeHeaderValue(9)}>Поддержать
                            </Link>
                        </div>
                    </div>
                </div>
            }
        </section>
    )
}



export default Header