import React, { useEffect, useCallback } from "react"
import { connect } from "react-redux"
import {Routes, Route} from 'react-router-dom'
import HomePage from './pages/HomePage/HomePage';
import Investigations from './pages/Investigations/Investigations';
import './App.scss'
import Base from './pages/Base/Base';
import About from './pages/About/About';
import SupportUs from './pages/SupportUs/SupportUs';
import {getInitRequest} from './bll/AppReducers'
import PageInvestigation from "./pages/PageInvestigation/PageInvestigation";
import BasePersonalPage from "./pages/BasePersonalPage/BasePersonalPage";

function App(props) {

  const getData = useCallback(() => {
    props.getInitRequest()
  }, [props.getInitRequest])

  useEffect(() => {
    getData()
  },[getData])

  return (
    <Routes>
        <Route path='/' element = {<HomePage />}/>
        <Route path='/investigations' element = {<Investigations />}/>
        <Route path='/investigations/:id' element = {<PageInvestigation />}/>
        <Route path='/base' element = {<Base />}/>
        <Route path='/base/:id' element = {<BasePersonalPage />}/>
        <Route path='/about' element = {<About />}/>
        <Route path='/support' element = {<SupportUs />}/>
    </Routes>
  );
}

const mapStateToProps = (state) => ({
  data: state.AppReducers
})

export default connect(mapStateToProps, {getInitRequest})(App)
